###*
# @ngdoc object
# @name lpaMapUtils.factory:UnitStyleFactory
#
# @description
#
###

class UnitStyleFactory
  ### @ngInject ###
  constructor: (
    $log
    DispatchUnitStatus
    RestUtils
    DisplayLabel
  ) ->

    UnitStyleBase = {
      clusterStyleCache: {}
      dispatchUnitStatuses: {}
    }

    dusPromise = RestUtils.getFullList DispatchUnitStatus

    dusPromise.then (results) ->
      angular.forEach results, (status) ->
        UnitStyleBase.dispatchUnitStatuses[status.id] = status

    UnitStyleBase.style = (feature, options = {}) ->
      #/** @type {Object.<ol.geom.GeometryType, Array.<ol.style.Style>>} */

      _defaults =
        isProposal: false

      options = angular.extend {}, _defaults, options

      size = feature.get('features').length
      style = null


      if size == 1

        # Get the feature and style from cache
        feature = feature.get('features')[0]
        style = feature.get('_cachedStyle')

        # Get the proper colors for coloring this feature
        bgColor = UnitStyleBase.getStatusColor feature, 'color', "#FFFFFF"
        textColor = UnitStyleBase.getContrastingColor bgColor
        iconColor = UnitStyleBase.getStatusColor feature, 'iconColor', textColor

        # Get wether to show vehicle number or dispatch unit label
        activeLabel = DisplayLabel.getActiveKey()
        label = (feature.get(activeLabel).replace 'BRABO', 'B').replace 'INACTIEF', 'INACT'

        # If the cache style is empty
        if not style
          if options.isProposal == false
            style = UnitStyleBase.getUnitStyle
              opacity: 0.9
              bgColor: bgColor
              textColor: textColor
              iconColor: iconColor
              markerUrl: feature.get '_markerUrl'
              label: label
          else
            style = UnitStyleBase.getUnitProposalStyle
              opacity: 0.9
              bgColor: bgColor
              textColor: textColor
              iconColor: iconColor
              label: label
              markerUrl: feature.get '_markerUrl'
              estimation: feature.get 'estimation'

          feature.set '_cachedStyle', style

        return style

      else

        style = UnitStyleBase.clusterStyleCache[size]

        if not style
          style = UnitStyleBase.getClusterStyle
            label: size.toString()
          UnitStyleBase.clusterStyleCache[size] = style

      return style

    UnitStyleBase.getUnitStyle = (options) ->

      _defaults =
        opacity: 0.9
        bgColor: '#FFFFFF'
        textColor: '#222222'
        iconColor: '#222222'
        markerUrl: null
        textAlign: 'left'
        label: ''

      options = angular.extend {}, _defaults, options
      markerUrl = if options.markerUrl? then options.markerUrl else '/images/map-vehicle/police-vehicle.svg'

      style = [
          new ol.style.Style
            image: new ol.style.Icon
              #/** @type {olx.style.IconOptions} */
              anchor: [0.5, 1]
              anchorXUnits: 'fraction'
              anchorYUnits: 'fraction'
              offsetY: -1
              opacity: options.opacity
              src: "/images/map-rectangle/default-small.png"
              color: options.bgColor
            text: new ol.style.Text
              text: options.label
              offsetX: -7
              offsetY: -17
              scale: 1.2
              textAlign: options.textAlign
              fill: new ol.style.Fill
                color: options.textColor
        ,
          new ol.style.Style
            image: new ol.style.Icon
              #/** @type {olx.style.IconOptions} */
              anchor: [1.5, 1.4]
              anchorXUnits: 'fraction'
              anchorYUnits: 'fraction'
              opacity: 1
              # src: '/images/map-vehicle/police-vehicle.svg'
              src: markerUrl
              crossOrigin: "anonymous"
              color: options.iconColor
              scale: 0.8
      ]

      return style

    UnitStyleBase.getUnitProposalStyle = (options) ->

      _defaults =
        opacity: 0.9
        bgColor: '#FFFFFF'
        textColor: '#222222'
        iconColor: '#222222'
        markerUrl: null
        label: ''
        estimation: ''

      options = angular.extend {}, _defaults, options
      markerUrl = if options.markerUrl? then options.markerUrl else '/images/map-vehicle/police-vehicle.svg'

      style = [
          new ol.style.Style
            image: new ol.style.Icon
              #/** @type {olx.style.IconOptions} */
              anchor: [0.5, 1.1]
              anchorXUnits: 'fraction'
              anchorYUnits: 'fraction'
              opacity: options.opacity
              src: "/images/map-rectangle/default-proposal.png"
              color: options.bgColor
            text: new ol.style.Text
              text: options.label
              offsetX: -7
              offsetY: -23
              scale: 1.2
              textAlign: "left"
              fill: new ol.style.Fill
                color: options.textColor
        ,
          new ol.style.Style
            image: new ol.style.Icon
              #/** @type {olx.style.IconOptions} */
              anchor: [1.5, 1.7]
              anchorXUnits: 'fraction'
              anchorYUnits: 'fraction'
              opacity: 1
              src: markerUrl
              # src: "/images/map-vehicle/#{options.icon}.svg"
              crossOrigin: "anonymous"
              color: options.iconColor
              scale: 0.8
        ,
          new ol.style.Style
            text: new ol.style.Text
              text: options.estimation
              offsetY: -43
              offsetX: 26
              scale: 1.2
              textAlign: "right"
              fill: new ol.style.Fill
                color: options.textColor
      ]

      return style

    UnitStyleBase.getClusterStyle = (options = {}) ->

      _defaults =
        opacity: 1
        radius: 15
        strokeColor: [255,255,255,0.8]
        fillColor: '#3399CC'
        textColor: '#FFFFFF'
        label: ''

      options = angular.extend {}, _defaults, options

      style = new ol.style.Style
        image: new ol.style.Circle
          radius: options.radius
          stroke: new ol.style.Stroke
            color: options.strokeColor
            width: 2
          fill: new ol.style.Fill
            color: options.fillColor
        text: new ol.style.Text
          text: options.label
          scale: 1.5
          fill: new ol.style.Fill
            color: options.textColor

      return style

    UnitStyleBase.getStatusColor = (feature, colorType, defaultColor) ->

      status = feature.get '_status'
      if colorType == 'color'
        if status.unit.dispatchUnit? and status.unit.dispatchUnit.dispatchUnitStatus?
          return '#' + status.unit.dispatchUnit.dispatchUnitStatus.color
        else
          return defaultColor
      else if colorType == 'iconColor'
        hasNruTag = false
        angular.forEach status.devices, (device) ->
          angular.forEach device.tags, (tag) ->
            if tag == "NRU"
              hasNruTag = true
        if hasNruTag
          return "#FF0000"
        else
          return defaultColor


    UnitStyleBase.getContrastingColor = (color) ->
      luminance =
        r: 0.2126
        g: 0.7152
        b: 0.0722
      # your color
      r = parseInt(color.substring(1, 3), 16)
      g = parseInt(color.substring(3, 5), 16)
      b = parseInt(color.substring(5), 16)

      rgb = [
        r / 255
        g / 255
        b / 255
      ]
      i = rgb.length
      while i--
        rgb[i] = if rgb[i] <= 0.03928 then rgb[i] / 12.92 else ((rgb[i] + 0.055) / 1.055) ** 2.4
      thresh = luminance.r * rgb[0] + luminance.g * rgb[1] + luminance.b * rgb[2]
      # now if thresh > 0.22 set text color to #222
      # else set it to #ccc
      # you now have readable text on every hex background.

      if thresh > 0.22
        return "#000000"
      else
        return "#FFFFFF"

    return UnitStyleBase

angular
  .module 'lpaMapUtils'
  .factory 'UnitStyle', UnitStyleFactory
